import React from "react";
import Joi from "joi-browser";
import Form from "../../_components/common/form";
import Input from "../../_components/common/input";
import CatastalCategoryComponent from "../../_components/common/catastalCategory";
import Modal from "../../_components/common/modal";
import { cicassService, auth } from "../../_services";
import { config, history } from "../../_helpers";
import { ToastContainer, toast } from "react-toastify";
import InputRange from 'react-input-range';
import { standardMessage } from "../../_constants/";
import 'react-input-range/lib/css/index.css';

const apiUrl = "quotation";
const apiAgencyUrl = "ana_user/agencyvalue";
const apiUrlPDF = "quotation/pdf";
const apiUrlPreview = "quotation/preview";
//const apiDown = "upload/quotation";
const apiUrlPracticetype = "ana_practicetype";
const apiUrlPracticetypeId = "ana_practicetype/anauserid/";
const apiUrlFacilitation = "ana_facilitation";
//const apiUrlCatastalcategory = "ana_catastalcategory";
const apiUrlQuotationDetail = "v_quotation_detail/byquotationid/"; // X Igor: mi deve restituire una vista con al posto dell'id del dettaglio il numero di riga calcolato con SQL
const apiUrlQDSave = "quotation_detail";

class QuotationDetailPage extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: -1,
        trade_value: 0, // compravendita
        loan_value: 0,
        accountholder: "",
        agency_value: 0, //storno agenzia
        deposit_value: 0,
        taxcredit_value: 0,
        f23_value: 0,
        otherinfo: "",
        ana_user_id: -1,
        ana_practicetype_id: -1,
        ana_facilitation_id: -1,
        ana_quotationtype_id: -1,
        disbursements: 0,
        perc_first_house: 50,
        ispreview: 0
      },
      practicetype: [],
      facilitation: [],
      disbursementsNum: [],
      quotationDetail: [],
      //av: 0,
      maxQuotationDetailId: 0,
      errors: {},
      disableInput: false,
      loadingSave: false,
      loadingPreview: false,
      loadingDelete: false,
      disableButton: false,
      saveMessage: standardMessage.CMD_NEW,
      modalQuestion: "",
      visibleAgency: true,
      errorMsg: "test",
      errorMsgView: false,
      perc_first_houseRange: 50,
      catastalWarning: false, // show warning for catastal categories,
      instrumentalWarning: false,
      previewTot: -1
    };

    this.handleRange = this.handleRange.bind(this);
  }




  schema = {
    id: Joi.number(),
    trade_value: Joi.number()
      .required()
      .label("Valore Compravendita"),
    loan_value: Joi.number()
      .required()
      .label("Valore mutuo"),
    accountholder: Joi.string()
      .required()
      .max(255)
      .label("Intestatario"),
    agency_value: Joi.number()
      .required()
      .max(1500)
      .label("Onere segnalazione"), //storno agenzia
    deposit_value: Joi.number()
      .required()
      .label("Importo totale caparre"),
    taxcredit_value: Joi.number()
      .required()
      .label("Credito di imposta"),
    f23_value: Joi.number()
      .required()
      .label("F23 Compromesso"),
    disbursements: Joi.number()
      .required()
      .label("Erogazioni successive"),
    perc_first_house: Joi.number()
      .required()
      .label("Percentuale prima casa"),
    otherinfo: Joi.string()
      .empty('')
      .max(255)
      .label("Note"),
    ana_user_id: Joi.number(),
    ana_practicetype_id: Joi.number()
      .required()
      .label("Tipo Pratica"),
    ana_facilitation_id: Joi.number()
      .required()
      .label("Agevolazione"),
    ana_quotationtype_id: Joi.number()
      .required()
      .label("Tipo"),
    ispreview: Joi.number()
  };

  async componentDidMount() {
    const isExpired = auth.checkExpired();
    const { data: facilitation } = await cicassService.getAll(apiUrlFacilitation);
    //console.log("quitivoglio:", facilitation);
    this.setState({ facilitation, disbursementsNum: [{ id: 0, name: "0" }, { id: 1, name: "1" }, { id: 2, name: "2" }, { id: 3, name: "3" }, { id: 4, name: "4" }, { id: 5, name: "5" }, { id: 6, name: "6" }, { id: 7, name: "7" }, { id: 8, name: "8" }, { id: 9, name: "9" }, { id: 10, name: "10" }] });
    //agency values
    const av = await cicassService.getAll(apiAgencyUrl);
    //this.setState({ av: av.data });
    //console.log(av);
    //if (av.data < 0) this.setState({ visibleAgency: false });

    if (av.data) this.setState({ visibleAgency: (av.data.agency_value_is_visible == 1) });

    const dataId = this.props.match.params.id;
    if (Number(dataId) === -1) {
      //se è una nuova quotation
      const { data: practicetype } = await cicassService.getAll(apiUrlPracticetype);
      this.setState({ practicetype, data: this.mapInit(av.data) });
      return;
    }
    // se è una quotation esistente
    const { data: quotationDetail } = await cicassService.getAll(apiUrlQuotationDetail + dataId);
    this.setState({ quotationDetail });
    if (quotationDetail.length > 0) {
      var maxQuotationDetailId = Math.max.apply(Math, quotationDetail.map(function (o) { return o.id; }));

      this.setState({ maxQuotationDetailId: maxQuotationDetailId })
    }
    //console.log(quotationDetail);
    const disableInput = true;
    this.setState({ disableInput, saveMessage: standardMessage.CMD_EDIT });
    // Non lo è faccio la GET gestisco errore o mapping
    const { data } = await cicassService.getById(apiUrl, dataId);
    if (data.id === 0) {
      history.replace("/not-found");
    }

    const anauserId = data.ana_user_id;
    const { data: practicetype } = await cicassService.getAll(apiUrlPracticetypeId + anauserId);
    this.setState({ practicetype, data: this.mapToViewModel(data), perc_first_houseRange: data.perc_first_house, isDefault: data.isDefault, isHidden: data.isHidden, viewOrder: data.viewOrder });
    this.handleCatastalWarning();
    this.handleInstrumentalWarning();
  }

  mapInit(av) {
    // console.log("init", av.agency_value);
    return {
      id: -1,
      trade_value: 0, // compravendita
      loan_value: 0, //mutuo
      accountholder: "", // cliente finale
      agency_value: Number(av.agency_value), //storno agenzia
      deposit_value: 0, //caparre
      taxcredit_value: 0, // credito di imposta
      f23_value: 0, //f23
      otherinfo: "", //note dell'affiliato
      ana_user_id: -1,
      ana_practicetype_id: -1,
      ana_facilitation_id: -1,
      ana_quotationtype_id: -1,
      disbursements: 0,
      perc_first_house: 0,
      ispreview: 0 // anteprima
    };
  }

  mapToViewModel(data) {
    // devo mappare i miei dati    
    return {
      id: Number(data.id),
      trade_value: Number(data.trade_value), // compravendita
      loan_value: Number(data.loan_value), //mutuo
      accountholder: data.accountholder, // cliente finale
      agency_value: Number(data.agency_value), //storno agenzia
      deposit_value: Number(data.deposit_value), //caparre
      taxcredit_value: Number(data.taxcredit_value), // credito di imposta
      f23_value: Number(data.f23_value), //f23
      otherinfo: data.otherinfo, //note dell'affiliato
      ana_user_id: Number(this.props.user.name),
      ana_practicetype_id: Number(data.ana_practicetype_id),
      ana_facilitation_id: Number(data.ana_facilitation_id),
      ana_quotationtype_id: Number(data.ana_quotationtype_id), //data.ana_facilitation_id,
      disbursements: Number(data.disbursements),
      perc_first_house: Number(data.perc_first_house), // quota prima casa
      ispreview: (this.state.ispreview ? 1 : 0) // anteprima 
    };
  }

  handleImmobili = (singleImmobile, operation) => {
    let new_state = Object.assign({}, this.state);
    let qD = new_state.quotationDetail;
    switch (operation) {
      case 'MODIFY':
        const index = qD.findIndex(myRow => myRow.id === singleImmobile.id);
        qD[index].rc_value = Number(singleImmobile.rc_value);
        qD[index].mapp = singleImmobile.mapp;
        qD[index].sub = singleImmobile.sub;
        qD[index].fg = singleImmobile.fg;
        if (singleImmobile.ana_catastalcategory_id) { // gestione categorie catastali
          qD[index].ana_catastalcategory_id = Number(singleImmobile.ana_catastalcategory_id);
          qD[index].ana_catastalcategory_description = singleImmobile.ana_catastalcategory_description;
        }
        else {
          qD[index].ana_catastalcategory_id = -1
          qD[index].ana_catastalcategory_description = "";
        }
        qD[index].second_box = singleImmobile.second_box; //Number(singleImmobile.second_box);
        qD[index].quotation_id = -1; // sara da gestire        
        break;
      case 'ADD':
        if (singleImmobile.id === -Infinity) singleImmobile.id = 1;
        qD.push(singleImmobile);
        break;
      case 'DELETE':
        const idxD = qD.findIndex(myRow => myRow.id === singleImmobile.id);
        qD.splice(idxD, 1);
        break;
      default: break;
    }
    this.handleCatastalWarning();
    this.handleInstrumentalWarning(); //2022-03-01
    var maxQuotationDetailId = Math.max.apply(Math, qD.map(function (o) { return o.id; }))
    this.setState({ maxQuotationDetailId: maxQuotationDetailId, quotationDetail: qD });

  };

  handleCatastalWarning(val = null) {
    const dbDetails = this.state.quotationDetail.slice(0, this.state.quotationDetail.length);
    let cntw = 0;
    if (val == -1) val = null;
    let ana_facilitation_id = (val > 0 ? Number(val) : Number(this.state.data.ana_facilitation_id));
    if (ana_facilitation_id === 1 || ana_facilitation_id === 3) { //se prima casa 6-12-17
      // agevolazione      
      if (dbDetails.length > 1) { // se almeno 2 immobili    
        var accw = [2, 3, 4, 5, 6, 7, 11]; // CV+M persona giuridica? Preliminare trascritto?    
        //check warning email Lorenzo 2021-03-19
        accw.forEach((i) => { //verificare se la lenght di qualcuno è maggiore di 2            
          cntw = cntw + dbDetails.filter(x => Number(x.ana_catastalcategory_id) === i).length;
        });
      }
    }
    this.setState({ catastalWarning: (cntw > 1) });
  }

  handleInstrumentalWarning(val = null) {
    //console.log("ci entro");
    let a = Number(this.state.data.ana_practicetype_id);
    if (a == 1 || a == 3 || a == 7 || a == 8) { //cv, cv+m cv pg, cv +m pg
      if (Number(this.state.data.ana_facilitation_id) != 4) { //non è bene strumentale
        const dbDetails = this.state.quotationDetail.slice(0, this.state.quotationDetail.length);
        if (dbDetails.length == 1) { // se è un immobile     
          if (dbDetails[0].ana_catastalcategory_description == "C/2" || dbDetails[0].ana_catastalcategory_description == "C/6") {
            this.setState({ instrumentalWarning: true });
            return;
          }
        }
      }
    }

    this.setState({ instrumentalWarning: false });
  }

  handleChangeFacility = ({ currentTarget: input }) => {
    //console.log("ah facility");
    const value = input.value;
    const errors = { ...this.state.errors };
    if (!(input.type === "checkbox")) {
      const errorMessage = this.validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
    }
    const data = { ...this.state.data };
    data[input.name] = value;
    //gestione warning
    this.handleCatastalWarning(input.value);
    this.setState({ data, errors });
  };

  handleChangePractice = ({ currentTarget: input }) => {
    const value = input.value;

    const errors = { ...this.state.errors };
    if (!(input.type === "checkbox")) {
      const errorMessage = this.validateProperty(input);
      if (errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];
    }
    const data = { ...this.state.data };
    data[input.name] = value;
    if (this.state.data.id != -1) this.checkAlert(input.value);
    this.setState({ data, errors });
  };



  handleModalMessage = idBtn => {
    switch (idBtn) {
      case "d": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_DELETE });
        break;
      }
      case "s": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_SAVE });
        break;
      }
      case "cv": {
        this.setState({ modalQuestion: standardMessage.MODAL_CVM });
        break;
      }
      case "p": {
        this.setState({ modalQuestion: standardMessage.MODAL_QUESTION_PREVIEW });
        break;
      }
      default:
        break;
    }
  };

  handleModalConfirm = () => {
    switch (this.state.modalQuestion) {
      case standardMessage.MODAL_QUESTION_DELETE: {
        this.handleDelete();
        break;
      }
      case standardMessage.MODAL_QUESTION_SAVE: {
        //console.log("arrivo qui");
        this.doSubmit(false);
        break;
      }
      case standardMessage.MODAL_QUESTION_PREVIEW: {
        //console.log("preview");
        this.doSubmit(true);
        break;
      }
      default:
        break;
    }
  };

  handleDelete = async () => {
    this.setState({ loadingDelete: true, disableButton: true });
    try {
      await cicassService.deleteOp(apiUrl, this.state.data.id);
      await toast.success(standardMessage.CMD_DELETE_OK);
      this.setState({ loadingDelete: false });
      window.location = "/quotations";
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        await toast.error(standardMessage.CMD_DELETE_KO);
      this.setState({ loadingDelete: false });
    }
    this.setState({ disableButton: false });
  };

  evaluateElem(array, val = null) {
    // console.log("Id: ", this.state.data.ana_practicetype_id);
    // console.log("vettore: ", (array.includes(Number(this.state.data.ana_practicetype_id))));

    if (val == -1) val = null;
    let ana_practicetype_id = (val > 0 ? Number(val) : Number(this.state.data.ana_practicetype_id));
    return (array.includes(ana_practicetype_id));
  }

  evaluateFacility(item) {
    switch (Number(this.state.data.ana_facilitation_id)) {
      case 1: //prima casa        
        return [2, 3, 4, 5, 6, 7, 11, 21, 25, 26].includes(Number(item));
      case 2: //seconda casa
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 21, 25, 26].includes(Number(item));
      case 3: //prima-seconda casa in quote
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 21, 25, 26].includes(Number(item));
      case 4: // bene strumentale
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 20, 21, 22, 25, 26].includes(Number(item));
    }
  }

  checkAlert(val = null) {
    //controlli di inserimento!!
    // compravendita maggiore del mutuo
    if (this.evaluateElem([1, 2, 7], val)) {
      if (Number(this.state.data.trade_value) < Number(this.state.data.loan_value)) {
        this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_CVM });
        //await toast.warn(standardMessage.MSG_CVM);
        return false;
      }
    }

    const dbDetails = this.state.quotationDetail.slice(0, this.state.quotationDetail.length);

    // agevolazione
    if (this.evaluateElem([1, 2, 3, 6, 7, 8], val)) {
      if (Number(this.state.data.ana_facilitation_id) < 1) {
        this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_MISSFAC });
        //await toast.warn(standardMessage.MSG_CVM);
        return false;
      }

      if (Number(this.state.data.ana_facilitation_id) === 1) { //se prima casa
        var c6cnt = 0;
        for (var i = 0; i < dbDetails.length; i++) {
          c6cnt = c6cnt + 1 * (Number(dbDetails[i].ana_catastalcategory_id) === 25) * (1 - Number(dbDetails[i].second_box));
        }
        if (c6cnt > 1) {
          this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_2CC });
          return false;
        }
      }

      if (Number(this.state.data.ana_facilitation_id) === 2 || Number(this.state.data.ana_facilitation_id) === 4) { //se 2a casa o bene strumentale       
        //console.log("entro qui");
        for (var i = 0; i < dbDetails.length; i++) {
          if (Number(dbDetails[i].second_box) > 0) {
            this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_NOBOX });
            return false;
          }
        }
      }

      if (this.state.data.trade_value <= 0) { //compravendita
        this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_TRADE });
        return false;
      }
      // console.log(dbDetails);

      for (var i = 0; i < dbDetails.length; i++) {
        //console.log(dbDetails[i]);
        if ((dbDetails[i].rc_value == "" || (dbDetails[i].rc_value == 0) || (dbDetails[i].rc_value == "0")) && (dbDetails[i].fg != "" && dbDetails[i].mapp != "" && dbDetails[i].sub != "")) {
          this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_IMM2 });
          return false;
        }
      }
    }
    //almeno una rendita catastale
    if (this.evaluateElem([1, 2, 3], val)) {
      if ((dbDetails.length < 1) && (Number(this.state.data.ana_facilitation_id) < 4)) { //se non ho selezionato bene strumentale
        this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_IMM });
        //await toast.warn(standardMessage.MSG_IMM);        
        return false;
      }
      //2020-03-10 email Lorenzo
      if (Number(this.state.data.ana_facilitation_id) === 1 || Number(this.state.data.ana_facilitation_id) === 3) { //se prima casa        
        if (dbDetails.length > 1) { // se almeno 2 immobili     email Lorenzo 2021-03-19
          var ccce = 0; // conteggio catastal category empty
          for (var i = 0; i < dbDetails.length; i++) {
            if (Number(dbDetails[i].ana_catastalcategory_id) === -1) {
              ccce += 1;
              //console.log("immobile ", i);
              if (ccce > 0) { //almeno uno vuoto
                this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_3CC });
                return false;
              }
            }
          }
        }
        if (dbDetails.length > 1) { // se almeno 2 immobili           email Lorenzo 2021-03-19      2, 3, 4, 5, 6, 7, 11
          var acc = [1, 8, 9, 10, 20, 21, 22, 25, 26]; //creare array catastal category
          var qty = false;
          acc.forEach((i) => { //verificare se la lenght di qualcuno è maggiore di 2
            if (dbDetails.filter(x => Number(x.ana_catastalcategory_id) === i && !x.second_box).length > 1)
              qty = true;
            return false;
          });
          if (qty) {
            this.setState({ errorMsgView: qty, errorMsg: standardMessage.MSG_2CC });
            return false;
          }
        }
      }
    }
    //categorie catastali in linea
    let control = false;
    if (this.evaluateElem([1, 2, 3, 6, 7, 8], val)) {
      for (var i = 0; i < dbDetails.length; i++) {
        control = (this.evaluateFacility(dbDetails[i].ana_catastalcategory_id) || (dbDetails[i].ana_catastalcategory_id === -1));
        if (!control) {
          this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_FAC + dbDetails[i].ana_catastalcategory_description });
          return false;
        }
      }
    }
    //mutuo SAL > 0
    if (this.evaluateElem([5], val)) {
      if (this.state.data.loan_value <= 0) {
        this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_SAL });
        return false;
      }
    }
    this.setState({ errorMsgView: false });
    return true;
  }

  doSubmit = async (preview) => {
    const { user } = this.props;
    this.setState({ errorMsgView: false, errorMsg: "" });

    // controllo alerts
    let chk = this.checkAlert();
    if (!chk) return;

    const dbDetails = this.state.quotationDetail.slice(0, this.state.quotationDetail.length);
    //agency value >= 0 per affiliati
    if (auth.isRoleOK(auth.OperatorRole, user)) {
      if (this.state.data.agency_value < 0) {
        this.setState({ errorMsgView: true, errorMsg: standardMessage.MSG_AV });
        return;
      }
    }

    this.setState({ loadingSave: !preview, loadingPreview: preview, disableButton: true });
    const dataToSave = {
      id: Number(this.state.data.id),
      trade_value: Number(this.state.data.trade_value),
      loan_value: Number(this.state.data.loan_value),
      accountholder: this.state.data.accountholder,
      agency_value: Number(this.state.data.agency_value), //this.state.av,//data.agency_value,
      deposit_value: Number(this.state.data.deposit_value),
      taxcredit_value: Number(this.state.data.taxcredit_value),
      f23_value: Number(this.state.data.f23_value),
      otherinfo: this.state.data.otherinfo,
      ana_user_id: Number(this.props.user.name),
      ana_practicetype_id: Number(this.state.data.ana_practicetype_id),
      ana_facilitation_id: Number(this.state.data.ana_facilitation_id),
      ana_quotationtype_id: 1,
      disbursements: Number(this.state.data.disbursements),
      perc_first_house: Number(this.state.perc_first_houseRange),
      ispreview: ((preview && this.state.data.id < 0) ? 1 : 0)
    };
    const dbQuo = await cicassService.saveOp(apiUrl, dataToSave);


    for (var i = 0; i < dbDetails.length; i++) {
      delete dbDetails[i].ana_catastalcategory_description;
      dbDetails[i].ana_catastalcategory_id = Number(dbDetails[i].ana_catastalcategory_id);
      dbDetails[i].rc_value = Number(dbDetails[i].rc_value);
      dbDetails[i].second_box = Number(dbDetails[i].second_box);
      dbDetails[i].quotation_id = Number(dbQuo.data.id);
    }



    //console.log(dbDetails);
    // devo recuperare dall'oggetto perchè posso essere in insert
    await cicassService.postArray(apiUrlQDSave + "/array/" + dbQuo.data.id, dbDetails);
    // scelgo il template
    const choice = await cicassService.getById(apiUrlPDF, dbQuo.data.id);
    //console.log(choice.data);

    //aggiorno il tipo di quotation
    const dataToSave2 = {
      id: Number(dbQuo.data.id),//this.state.data.id,
      trade_value: Number(this.state.data.trade_value),
      loan_value: Number(this.state.data.loan_value),
      accountholder: this.state.data.accountholder,
      agency_value: Number(this.state.data.agency_value), //this.state.av, //data.agency_value,
      deposit_value: Number(this.state.data.deposit_value),
      taxcredit_value: Number(this.state.data.taxcredit_value),
      f23_value: Number(this.state.data.f23_value),
      otherinfo: this.state.data.otherinfo,
      ana_user_id: Number(this.props.user.name),
      ana_practicetype_id: Number(this.state.data.ana_practicetype_id),
      ana_facilitation_id: Number(this.state.data.ana_facilitation_id),
      ana_quotationtype_id: Number(choice.data),
      disbursements: Number(this.state.data.disbursements),
      perc_first_house: Number(this.state.perc_first_houseRange),
      ispreview: 0 // 2022-06-30 a seguito di mail di Lorenzo del 15/06/2022 ((preview && this.state.data.id < 0) ? 1 : 0)
    };
    const dbQuo2 = await cicassService.saveOp(apiUrl, dataToSave2);

    if (!preview) { // se non è l'anteprima
      // genero il pdf
      const nPDF = await cicassService.getById(apiUrlPDF + "/" + dbQuo.data.id, choice.data);
      await toast.success(standardMessage.CMD_SAVE_OK);
      this.setState({ loadingSave: false, loadingPreview: false, disableButton: false });
      window.location = "/quotations";
    }
    else {
      //mostro il totale
      let amount = -1;
      await cicassService.getById(apiUrlPreview + "/" + dbQuo.data.id, choice.data)
        .then((res) => {
          amount = Math.round((Number(res.data) + Number.EPSILON) * 100) / 100;
        })
        .catch(async (ex) => { await toast.error("Non è stato possibile calcolare l'anteprima.", ex) });



      // se è una quotation esistente
      const { data: quotationDetail } = await cicassService.getAll(apiUrlQuotationDetail + dbQuo.data.id);
      this.setState({ quotationDetail });
      if (quotationDetail.length > 0) {
        var maxQuotationDetailId = Math.max.apply(Math, quotationDetail.map(function (o) { return o.id; }));

        this.setState({ maxQuotationDetailId: maxQuotationDetailId })
      }

      //2022-06-30 a seguito di mail di Lorenzo del 15/06/2022
      // genero il pdf
      const nPDF = await cicassService.getById(apiUrlPDF + "/" + dbQuo.data.id, choice.data);
      await toast.success(standardMessage.CMD_SAVE_OK);

      this.setState({ loadingSave: false, loadingPreview: false, disableButton: false, previewTot: amount, data: dataToSave2 });
    }

    //this.props.history.push("/users");

  };

  firstRows = props => {
    return (
      <React.Fragment>
        <div className="row m-2">
          <div className={config.bstrp2col}>{this.renderSelect("ana_practicetype_id", "Tipo Pratica", this.state.practicetype, this.handleChangePractice)}</div>
        </div>
        <div className="row m-2">
          <div className={config.bstrp2col}>{this.renderInput("accountholder", "Intestatario prospetto spesa", "Nome e cognome")}</div>
        </div>
      </React.Fragment>
    );
  }

  cmpTrade = props => {
    const tradeAr = [1, 2, 3, 6, 7, 8];
    return (
      <React.Fragment>{this.evaluateElem(tradeAr) &&
        this.renderInput("trade_value", "Valore compravendita", "Valore in euro", "decimal", (!this.evaluateElem(tradeAr)), "[0-9]*", "number")}</React.Fragment>
    );
  }

  cmpLoan = props => {
    const loanAr = [1, 2, 4, 5, 7];
    return (
      <React.Fragment>{this.evaluateElem(loanAr) &&
        this.renderInput("loan_value", "Valore mutuo", "Valore in euro", "decimal", (!this.evaluateElem(loanAr)), "[0-9]*", "number")}</React.Fragment>

    );
  }

  cmpDisbur = props => {
    const salAr = [5];
    return (
      <React.Fragment>{this.evaluateElem(salAr) && this.renderSelect("disbursements", "N° Erogazioni", this.state.disbursementsNum)}</React.Fragment>
    );
  }

  cmpFacil = props => {
    const facilityAr = [1, 2, 3, 6, 7, 8];
    return (
      <React.Fragment>{this.evaluateElem(facilityAr) && this.renderSelect("ana_facilitation_id", "Agevolazione", this.state.facilitation, this.handleChangeFacility)}</React.Fragment>
    );
  }

  cmpAgency = props => {
    const agencyAr = [1, 2, 3, 4, 5, 6, 7, 8];
    return (
      <React.Fragment>{this.evaluateElem(agencyAr) && (this.state.visibleAgency) && this.renderInput("agency_value", "Onere segnalazione", "Valore in euro", "decimal", false, "[0-9]*", "number")}</React.Fragment>
    );
  }

  cmpDeposit = props => {
    const depositAr = [6];
    return (
      <React.Fragment>{this.evaluateElem(depositAr) && this.renderInput("deposit_value", "Importo totale caparre", "Valore in euro", "decimal", (!this.evaluateElem(depositAr)), "[0-9]*", "number")}</React.Fragment>

    );
  }

  cmpTaxcredit = props => {
    const { user } = this.props; // verificare sempre che non sia undefined
    const taxAr = [1, 2, 3];
    return (
      <React.Fragment>{auth.isRoleOK(auth.HighRole, user) && this.evaluateElem(taxAr) && this.renderInput("taxcredit_value", "Credito di imposta", "Valore in euro", "decimal", false, "[0-9]*", "number")}</React.Fragment>
    );
  }

  cmpF23 = props => {
    const { user } = this.props; // verificare sempre che non sia undefined
    const f23Ar = [1, 2, 3];
    return (
      <React.Fragment>{auth.isRoleOK(auth.HighRole, user) && this.evaluateElem(f23Ar) && this.renderInput("f23_value", "F23 Compromesso", "Valore in euro", "decimal", false, "[0-9]*", "number")}</React.Fragment>
    );
  }

  cmpRange = props => {
    return (this.state.data.ana_facilitation_id == 3 &&
      <React.Fragment>
        <div className="form-group mr-2">
          <div div className="form-group mr-2">&nbsp;</div><InputRange
            step={1}
            formatLabel={value => ``}
            maxValue={99}
            minValue={0}
            value={this.state.perc_first_houseRange}
            //onChange={e => this.setState({ perc_first_houseRange: e })}
            onChange={this.handleRange}
          //onChangeComplete={args => console.log(args)}
          />
          Prima Casa: <b>{this.state.perc_first_houseRange}%</b> - Seconda Casa: <b>{100 - Number(this.state.perc_first_houseRange)}%</b>
        </div>
      </React.Fragment>
    );
  }

  Rows = props => {
    let order;
    if (Number(this.state.data.ana_facilitation_id) == 3) {
      switch (Number(this.state.data.ana_practicetype_id)) {
        case 1:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpRange()], [this.cmpAgency(), null], [this.cmpTaxcredit(), this.cmpF23()]];
          break;
        case 2:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpRange()], [this.cmpAgency(), null], [this.cmpTaxcredit(), this.cmpF23()]];
          break;
        case 3:
          order = [[this.cmpTrade(), this.cmpFacil()], [null, this.cmpRange()], [this.cmpAgency(), null], [this.cmpTaxcredit(), this.cmpF23()]];
          break;
        case 4:
          order = [[this.cmpLoan(), null], [this.cmpAgency(), null]];
          break;
        case 5:
          order = [[this.cmpLoan(), this.cmpDisbur()], [this.cmpAgency(), null]];
          break;
        case 6:
          order = [[this.cmpTrade(), this.cmpFacil()], [null, this.cmpRange()], [this.cmpDeposit(), this.cmpAgency()]];
          break;
        case 7:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpRange()], [this.cmpAgency(), null]];
          break;
        case 8:
          order = [[this.cmpTrade(), this.cmpFacil()], [null, this.cmpRange()], [this.cmpAgency(), null]];
          break;
        default:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpRange()], [this.cmpAgency(), null], [this.cmpTaxcredit(), this.cmpF23()]];
      }
    }
    else {
      switch (Number(this.state.data.ana_practicetype_id)) {
        case 1:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpAgency()], [this.cmpTaxcredit(), this.cmpF23()]];
          break;
        case 2:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpAgency()], [this.cmpTaxcredit(), this.cmpF23()]];
          break;
        case 3:
          order = [[this.cmpTrade(), this.cmpFacil()], [this.cmpAgency(), null], [this.cmpTaxcredit(), this.cmpF23()]];
          break;
        case 4:
          order = [[this.cmpLoan(), null], [this.cmpAgency(), null]];
          break;
        case 5:
          order = [[this.cmpLoan(), this.cmpDisbur()], [this.cmpAgency(), null]];
          break;
        case 6:
          order = [[this.cmpTrade(), this.cmpFacil()], [this.cmpDeposit(), this.cmpAgency()]];
          break;
        case 7:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpAgency()]];
          break;
        case 8:
          order = [[this.cmpTrade(), this.cmpFacil()], [this.cmpAgency(), null]];
          break;
        default:
          order = [[this.cmpTrade(), this.cmpLoan()], [this.cmpFacil(), this.cmpAgency()], [this.cmpTaxcredit(), this.cmpF23()]];
      }
    }

    const listItems = order.map((number, item) =>
      <div className="row m-2" key={item}>
        <div className={config.bstrp4col}>{number[0]}</div>
        <div className={config.bstrp4col}>{number[1]}</div>
      </div>
    );

    return (
      <React.Fragment>
        {listItems}
      </React.Fragment>);
  }

  handleRange = e => {
    //if (e) console.log(e);
    if (e) this.setState({ perc_first_houseRange: e });
  }


  render() {
    const {
      quotationDetail,
      loadingSave,
      loadingPreview,
      loadingDelete,
      saveMessage,
      disableButton,
      modalQuestion,
      errorMsg,
      errorMsgView,
      previewTot
    } = this.state;
    const dataId = this.props.match.params.id;
    const { user } = this.props;
    const detailAr = [1, 2, 3, 6, 7, 8];
    const perc_first_houseRange = this.state.perc_first_houseRange;

    return (
      <div className="container-fluid">

        <ToastContainer autoClose={config.autoCloseToast} />
        <div className="row m-4">
          <div className="col">
            <h1>Prospetto spesa</h1>
          </div>
          <div className="float-right">
            {this.renderButtonLink(standardMessage.CMD_BACK, "/quotations")}
          </div>
        </div>
        <div>
          {errorMsgView && <div className="row m-4 bg-info text-white breadcrumb">{errorMsg}</div>}
          <form onSubmit={this.handleSubmit}>
            {this.firstRows()}
            {this.Rows()}

          </form>
          <div className="row-12 m-2">
            {this.state.quotationDetail.length > - 1 && this.evaluateElem(detailAr) &&
              (<>
                <CatastalCategoryComponent key={"cat1"} name="quotationDetail" label="Immobili" datalist={this.state.quotationDetail} maxId={this.state.maxQuotationDetailId} onEdit={this.handleImmobili} />
                {this.state.catastalWarning && <div className="row m-4 bg-warning breadcrumb">{standardMessage.WARNING_CATASTAL}</div>}
                {this.state.instrumentalWarning && <div className="row m-4 bg-warning breadcrumb">{standardMessage.WARNING_INSTRUMENTAL}</div>}
              </>
              )
            }
          </div>
          <div className="row-12 m-4">
            {this.renderTextarea("otherinfo", "Note interne", "Informazioni aggiuntive che non andranno nel PDF...")}
          </div>
          <div className="row-12">
            {this.renderButtonModal(standardMessage.CMD_PREVIEW, "modalOp", true, loadingPreview, disableButton, () => { return this.handleModalMessage("p"); }, "btn btn-outline-primary  ml-4 mr-4 mb-4")
            }
            {previewTot > -1 &&
              <div className="preview">
                Totale prospetto spesa: <b>Euro {previewTot}</b>
              </div>}
          </div>
          {errorMsgView && <div className="row m-4 bg-info text-white breadcrumb">{errorMsg}</div>}
          {this.renderButtonModal(saveMessage, "modalOp", true, loadingSave, disableButton, () => { return this.handleModalMessage("s"); }, "btn btn-outline-success ml-4 mr-4 mb-4")
          }
          {dataId > -1 && auth.isRoleOK(auth.HighRole, user) && this.renderButtonModal(standardMessage.CMD_DELETE, "modalOp", true, loadingDelete, disableButton, () => { return this.handleModalMessage("d"); }, "btn btn-outline-danger mb-4")
          }
        </div>
        <Modal modalId="modalOp" modalTitle={standardMessage.MODAL_TITLE_ATTENTION} modalBody={modalQuestion} onModalConfirm={this.handleModalConfirm} />
        <div className="preview" style={{ fontStyle: "italic", fontSize: "smaller" }}>Attenzione: non inserire informazioni non pertinenti come dati di soggetti che non abbiano dato incarico all’Agenzia oppure dati di salute o altri dati
          non strettamente pertinenti al prospetto spesa</div>
        <div>&nbsp;</div>
      </div >
    );
  }
}
export { QuotationDetailPage };
